<template>
  <div class="relative bg-blue">
    <!-- <back class="m-b-sm" :url="getUrl()" /> -->
    <div class="flex items-center  justify-between">
      <ul class="tab-list flex items-center">
        <li
          class="tab-item text-white pointer"
          v-for="(item, key) in tabs"
          :key="key"
          :class="{ 'text-lightblue current': curIndex === item.index }"
          @click="selectCurrent(item)"
        >
          {{ item.name }}
        </li>
      </ul>
      <span class="close f20 text-white weight pointer" v-show="false">×</span>
    </div>
  </div>
</template>

<script>
// import Back from './Back';
export default {
  name: 'Tab',
  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    initTab: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // Back,
  },
  data() {
    return {
      curIndex: 0,
    };
  },
  computed: {},
  watch: {
    initTab(nv, ov) {
      if (nv != ov) {
        this.setDefaultCurrentIndex();
      }
    },
  },
  created() {
    this.setDefaultCurrentIndex();
  },
  methods: {
    getUrl() {
      return;
    },
    setDefaultCurrentIndex() {
      this.curIndex = this.initTab;
    },
    selectCurrent(item) {
      /* if (item.index === 6 && !this.$_btnPermissionCheck('109216')) {
        this.$message.error('你没有权限访问！');
        return;
      } */
      this.curIndex = item.index;
      this.$emit('select', this.curIndex);
      /*  switch (index) {
        case 0:
          this.$router.push({ path: '/user-manage' });
          break;
        case 1:
          this.$router.push({ path: '/role-manage' });
          break;
        case 2:
          this.$router.push({ path: '/act-action' });
          break;
        case 3:
          this.$router.push({ path: '/log-action' });
          break;
        case 4:
          this.$router.push({ path: '/camera-dict' });
          break;
        case 5:
          this.$router.push({ path: '/store-manage' });
          break;
        case 6:
          this.$router.push({ path: '/system-setting' });
          break;
      } */
    },
  },
};
</script>

<style scoped>
.tab-item {
  margin: 0 10px;
  line-height: 32px;
}
.tab-item:first-child{
  margin-left: 0 !important;
}
.current {
  border-bottom: 2px solid #86b3e9;
}
</style>
