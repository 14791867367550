// 定义一个混入对象
import { mapState } from 'vuex';
const tabMixin = {
  data() {
    return {
      tabs: [
        // { name: '系统消息' },
        // { name: '截图管理' },
        { name: '用户管理', index: 0, filter: 'user' },
        { name: '角色管理', index: 1, filter: 'role' },
        { name: '行为审计', index: 2, filter: 'admin' },
        { name: '操作日志', index: 3, filter: 'ope' },
        { name: '数据字典', index: 4, filter: 'admin' },
        { name: '存储管理', index: 5, filter: 'admin' },
        { name: '权限管理', index: 7, filter: 'admin' },
        { name: '系统设置', index: 6, filter: 'admin' }
      ],
      initTab: 0
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    if (this.userInfo['role'] !== '100000001') {
      this.tabs = this.tabs.filter(item => item.filter != 'admin');
    }
    this.initTab = Number(this.$route.query.initTab);
    // console.log('haha', this.$route, this.initTab);
  },
  methods: {
    selectCurrent(index) {
      this.initTab = index;
      switch (index) {
        case 0:
          this.$router.push({ path: '/system/user-manage?initTab=0' });
          break;
        case 1:
          this.$router.push({ path: '/system/role-manage?initTab=1' });
          break;
        case 2:
          this.$router.push({ path: '/system/act-action?initTab=2' });
          break;
        case 3:
          this.$router.push({ path: '/system/log-action?initTab=3' });
          break;
        case 4:
          this.$router.push({ path: '/system/camera-dict?initTab=4' });
          break;
        case 5:
          this.$router.push({ path: '/system/store-manage?initTab=5' });
          break;
        case 6:
          this.$router.push({ path: '/system/system-setting?initTab=6' });
          break;
        case 7:
          this.$router.push({ path: '/system/authority-manage?initTab=7' });
          break;
      }
    }
  }
};
export default tabMixin;
