const ResizableTable = {
    data(){
        this.resibleTableHeader = {
            header: {
              cell: (h, props, children) => {
                const { key, ...restProps } = props
                // console.log('ResizeableTitle', key)
                let columns = [];
                if(this.activeMenu == 1){
                  columns = this.columns;
                }else if(this.activeMenu == 2) {
                  columns = this.columnsCheck;
                }else{
                  columns = this.columns;
                }
                const col = columns.find(col => {
                  const k = col.dataIndex || col.key
                  return k === key
                })
      
                if (!col || !col.width) {
                  return h('th', { ...restProps }, [...children])
                }
      
                const dragProps = {
                  key: col.dataIndex || col.key,
                  class: 'table-draggable-handle',
                  attrs: {
                    w: 10,
                    x: col.width,
                    z: 1,
                    axis: 'x',
                    draggable: true,
                    resizable: false
                  },
                  on: {
                    dragging: (x) => {
                      col.width = Math.max(x, 1)
                    }
                  }
                }
                const drag = h('vue-draggable-resizable', { ...dragProps })
                let className = 'resize-table-th ';
                className += restProps.class ? restProps.class:'';
                return h('th', { ...restProps, class: className }, [...children, drag])
              }
            }
          }
        return {

        }
    }
}
export default ResizableTable;