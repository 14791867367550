<template>
  <div>
    <a-modal
      title="添加权限"
      :visible="visible"
      width="30%"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs sp-title"
            ><span class="text-red">*</span>权限名称：</span
          >
          <a-input
            v-model="authorityName"
            :maxLength="20"
            placeholder="请输入权限名称"
          />
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs sp-title">上级权限：</span>
          <a-cascader
            :options="authorityArr"
            v-model="currentAuthorityArr"
            change-on-select
            class="full-width"
            placeholder="请选择上级权限"
            @change="selectAuthority"
          />
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs sp-title">权限类型：</span>
          <a-radio-group v-model="currentAuthority" @change="onChangeType">
            <a-radio-button
              v-for="item in authorityTypes"
              :value="item.value"
              :key="item.id"
            >
              {{ item.text }}
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs sp-title">访问地址：</span>
          <a-input v-model="authorityUrl" placeholder="请输入访问地址" />
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs sp-title">权限状态：</span>
          <a-radio-group v-model="status">
            <a-radio value="1">
              启用
            </a-radio>
            <a-radio value="0">
              禁用
            </a-radio>
          </a-radio-group>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs sp-title">描述：</span>
          <a-textarea
            v-model="desc"
            placeholder="请输入描述信息"
            :maxLength="200"
          />
        </div>
        <div class="flex m-t-md items-center justify-center">
          <a-button
            type="primary"
            class="btn-primary m-r-sm"
            @click="sureHandle"
            >确定</a-button
          >
          <a-button class="btn-reset" @click="handleCancel">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import { CODE_OK } from '@/request/config_code';
import { formatAuthorityTree } from '@/tools';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'SingleDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentRecord: {
      type: Object,
      default() {
        return {};
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authorityName: '新建权限',
      authorityTypes: [
        {
          id: 0,
          text: '菜单',
          value: '00',
        },
        {
          id: 1,
          text: '页面',
          value: '10',
        },
        {
          id: 2,
          text: '按钮',
          value: '20',
        },
      ],
      currentAuthority: '00',
      status: '1',
      authorityUrl: '',
      desc: '',
      authorityArr: [],
      currentAuthorityArr: [],
    };
  },
  computed: {},
  watch: {
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this.authorityArr = formatAuthorityTree(this.list);
        if (this.isEdit) {
          this.initCurrentData();
        }
      }
    },
  },
  created() {
    this.authorityArr = formatAuthorityTree(this.list);
  },
  methods: {
    handleCancel() {
      this.resetHandle();
      this.$emit('close');
    },
    initCurrentData() {
      this.status = this.currentRecord.status;
      this.authorityName = this.currentRecord.functionDesc;
      this.currentAuthority = this.currentRecord.functionType;
      this.authorityUrl = this.currentRecord.functionUrl;
      this.desc = this.currentRecord.showText;
      this.currentAuthorityArr = this.getCurrentAthorityArr(
        this.list,
        this.currentRecord.parentCode
      );
    },
    getCurrentAthorityArr(dataArr, id) {
      let temp = [];
      let forFn = function(arr, id) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (item.functionCode === id) {
            temp.push(item.functionCode);
            forFn(dataArr, item.parentCode);
            break;
          } else {
            if (item && item.children && item.children.length > 0) {
              forFn(item.children, id);
            }
          }
        }
      };
      forFn(dataArr, id);
      return temp.reverse();
    },
    getCurrentParentCode(arr, functionCode) {
      console.log(functionCode);
      let parentCode = '';

      let fun = (arr, functionCode) => {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (item.functionCode === functionCode) {
            parentCode = item.parentCode;
            break;
          } else if (item && item.children && item.children.length > 0) {
            fun(item.children, functionCode);
          }
        }
      };
      fun(arr, functionCode);
      return parentCode;
    },
    onChangeType(value) {
      console.log(value);
    },
    selectAuthority(value) {
      this.currentAuthorityArr = value;
    },
    checkAuthority() {
      return this.$api.authority
        .searchRepeatFunction(this.authorityName)
        .then((res) => {
          // debugger;
          if (res.code !== CODE_OK) {
            this.$message.error(res.message);
            return false;
          } else {
            return true;
          }
        });
    },
    sureHandle() {
      let obj = {
        functionDesc: this.desc.trim(),
        functionName: this.authorityName.trim(),
        functionType: this.currentAuthority,
        parentCode:
          this.currentAuthorityArr.length > 0
            ? this.currentAuthorityArr[this.currentAuthorityArr.length - 1]
            : '1092',
        path: this.authorityUrl.trim(),
        status: this.status,
      };
      if (!obj.functionName) {
        this.$message.error('权限名不能为空');
        return;
      }
      if (!obj.parentCode) {
        this.$message.error('请选择上级权限');
        return;
      }
      if (obj.path && /[\u4E00-\u9FA5]+/.test(obj.path)) {
        this.$message.error('权限访问地址，不支持中文输入');
        return;
      }

      if (!this.isEdit) {
        if (!this.checkAuthority()) {
          return false;
        }
        this.$api.authority.addFunction(obj).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('权限新增成功');
            this.$emit('sure');
            this.resetHandle();
          }
        });
      } else {
        obj.functionCode = this.currentRecord.functionCode;
        this.$api.authority.modifyFunction(obj).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('权限修改成功');
            this.$emit('sure');
            this.resetHandle();
          }
        });
      }
    },
    resetHandle() {
      this.authorityName = '新建权限';
      this.currentAuthority = '00';
      this.status = '1';
      this.authorityUrl = '';
      this.desc = '';
      this.currentAuthorityArr = [];
    },
  },
};
</script>

<style scoped>
.sp-title {
  flex: 0 0 80px;
  text-align: right;
}
.equal {
  text-align: center;
  border: 1px solid #4c658a;
  line-height: 32px;
  flex: 0 0 40px;
  border-radius: 3px;
}
</style>
