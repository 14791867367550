<template>
  <div class="full-width full-height">
    <tab :tabs="tabs" :initTab="initTab" @select="selectHandle" />
    <div class="m-t">
      <div class="m-l-md">
        <div class="flex justify-between items-center m-b-sm">
          <div class="flex flex-one text-lightblue">
            <div class="flex m-r-sm items-center  search-mx-wd-2">
              <span class="flex-s m-r-xs">权限状态：</span>
              <a-select
                v-model="searchParams.status"
                class="full-width"
                placeholder="请选择权限状态"
              >
                <a-select-option :value="1">正常</a-select-option>
                <a-select-option :value="0">禁用</a-select-option>
              </a-select>
            </div>
            <div class="flex m-r-sm items-center search-mx-wd-2">
              <span class="flex-s m-r-xs">权限类型：</span>
              <a-select
                v-model="searchParams.type"
                class="full-width"
                placeholder="请选择权限类型"
              >
                <a-select-option :value="'00'">菜单</a-select-option>
                <a-select-option :value="'10'">页面</a-select-option>
                <a-select-option :value="'20'">按钮</a-select-option>
              </a-select>
            </div>
            <div class="flex m-r-sm items-center search-mx-wd-2">
              <span class="flex-s m-r-xs">权限名称：</span>
              <a-input
                v-model="searchParams.name"
                class="full-width"
                placeholder="请输入权限名称"
              />
            </div>

            <a-button
              type="primary"
              class="m-r-sm btn-search"
              @click="searchHandle"
            >
              搜索
            </a-button>
            <a-button class="btn-reset m-r-sm" @click="resetHandle">
              重置
            </a-button>
          </div>
          <div class="flex">
            <span class="span-line m-l-md m-r-md"></span>
            <a-button class="btn-export pointer" @click="handleAdd">
              新增
            </a-button>
          </div>
        </div>

        <a-table
          :rowKey="(row) => row.key"
          :components="resibleTableHeader"
          :columns="columns"
          :data-source="data"
          :pagination="false"
          :scroll="scroll"
          :loading="loading"
          bordered
        >
          <template slot="customStatus" slot-scope="text">
            <!-- <a-checkbox
                :defaultChecked="text === '1' ? true : false"
                @change="onChangeStatus(record)"
              ></a-checkbox> -->
            <a-icon type="check-circle" class="f20" v-if="text === '1'" />
            <a-icon type="close-circle" class="f20" v-else />
          </template>
          <template slot="customOpe" slot-scope="text, record">
            <a-tooltip placement="top">
              <template slot="title">
                <span>编辑</span>
              </template>
              <a-icon
                type="edit"
                class="m-r-sm pointer"
                @click="editHandle(record)"
              />
            </a-tooltip>
          </template>
        </a-table>
      </div>
    </div>
    <modify-dialog
      :currentRecord="currentRecordData"
      :visible="visibleModify"
      :isEdit="isEdit"
      :list="data"
      @close="closeModifyDialog"
      @sure="sureHandle"
    />
  </div>
</template>

<script>
import Tab from '@/components/Tab';
import tabMixin from '@/components/mixins/tabs';
import { CODE_OK } from '@/request/config_code';
import ModifyDialog from './ModifyDialog';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'StoreManage',
  mixins: [tabMixin,ResizableTable],
  data() {
    return {
      scroll: { y: window.innerHeight - 290,x:'100%' },
      loading: false,
      searchParams: {
        status: undefined,
        type: undefined,
        name: '',
      },

      columns: [
        {
          title: '序号',
          dataIndex:'funcIndex',
          customRender: (text, record, index) => index + 1,
          width: 120,
          ellipsis: true,
        },
        {
          title: '权限名称',
          dataIndex: 'functionDesc',
          width: 120,
        },
        {
          title: 'code',
          dataIndex: 'functionCode',
          width: 120,
          ellipsis: true,
        },
        {
          title: '权限URL',
          dataIndex: 'functionUrl',
          width: 240,
          ellipsis: true,
        },
        {
          title: '权限描述',
          dataIndex: 'showText',
          width: 240,
          ellipsis: true,
        },
        {
          title: '权限类型',
          dataIndex: 'functionType',
          width: 100,
          ellipsis: true,
          customRender: (text) => {
            return text === '00' ? '菜单' : text === '10' ? '页面' : '按钮';
          },
          className:'column-center'
        },
        {
          title: '权限状态',
          dataIndex: 'status',
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'customStatus' },
          className:'column-center'
        },
        {
          title: '操作',
          dataIndex: 'ope',
          scopedSlots: {
            customRender: 'customOpe',
          },
          width: 60,
          className:'column-center'
        },
      ],
      data: [],
      visibleModify: false,
      curIndex: 0,
      currentRecordData: {},
      isEdit: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this._getAuthority();
  },
  methods: {
    getHeight() {
      let winHeight = window.innerHeight;
      return { height: `${winHeight - 220}px` };
    },
    selectHandle(index) {
      this.selectCurrent(index);
    },
    _getAuthority() {
      let obj = {
        enable: this.searchParams.status,
        functionType: this.searchParams.type,
        functionName: this.searchParams.name,
        sceneType: 1,
      };
      this.loading = true;
      this.$api.authority.getAuthority(obj).then((res) => {
        if (res.code === CODE_OK) {
          this.data = this.trackAuthority(res.data);
          this.loading = false;
        }
      });
    },
    trackAuthority(authority, key = '1') {
      let arr = [];
      if (authority.length > 0) {
        arr = authority.map((item, index) => {
          item.key = `${key}-${index}`;
          item.order = ++this.curIndex;
          if (item.childNode && item.childNode.length > 0) {
            item.children = this.trackAuthority(item.childNode, item.key);
          }
          return item;
        });
      }

      return arr;
    },
    searchHandle() {
      /* this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10; */
      this._getAuthority();
    },
    resetHandle() {
      this.searchParams = {
        status: undefined,
        type: undefined,
        name: '',
        sceneType: 1,
      };
      this._getAuthority();
    },
    onChangeStatus(record) {
      console.log(record);
    },
    handleAdd() {
      this.visibleModify = true;
      this.isEdit = false;
    },
    closeModifyDialog() {
      this.visibleModify = false;
      this.isEdit = false;
    },
    sureHandle() {
      this.closeModifyDialog();
      this._getAuthority();
    },
    editHandle(record) {
      this.currentRecordData = record;
      this.isEdit = true;
      this.visibleModify = true;
    },
  },
  components: {
    Tab,
    ModifyDialog,
  },
};
</script>

<style scoped>
.left {
  flex: 0 0 200px;
  width: 200px;
}
.w20 {
  width: 60%;
}
.wd-20 {
  width: 20%;
}
</style>
